import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { ChangedetectorReference } from '../../../../../../../../../core/changedetector/changedetectoreference';
import { NGXSimpleSeriesChartComponent } from '../../ngx-simple-series-chart.class';
import {
  BarChartDisplayOptions,
  SimpleVerticalBarChart
} from '../../../../../../../../../core/models/ETG_SABENTISpro_Application_Core_models';
import { isNullOrUndefined } from '../../../../../../../../../shared/utils/typescript.utils';

@Component({
  selector: 'app-simple-vertical-bar-chart',
  templateUrl: './simple-vertical-bar-chart.component.html',
  styleUrls: ['./simple-vertical-bar-chart.component.scss'],
  providers: [ChangedetectorReference]
})
export class SimpleVerticalBarChartComponent extends NGXSimpleSeriesChartComponent<SimpleVerticalBarChart, BarChartDisplayOptions> implements OnInit {

  constructor(protected cdReference: ChangedetectorReference,
              protected elementRef: ElementRef) {
    super(cdReference);
  }

  GetViewValueOnInitializeChart(): [number, number] {
    return this.GetDefaultViewValueOnInitializeChart(this.elementRef);
  }

  GetViewValueOnResize(): [number, number] {
    return this.GetDefaultViewValueOnResize(this.elementRef);
  }
  ngAfterViewInit(): void {
    this.adjustTextWidth();
  }

  adjustTextWidth() {
    const element = this.elementRef.nativeElement;
    const firstBar = element.querySelector('.bar');
    if (!isNullOrUndefined(firstBar)) {
      const d = firstBar.getAttribute('d');
      const widthBar = parseFloat(d.split('h')[1].split('a')[0]);
      const ticks = document.querySelectorAll('g[ngx-charts-x-axis] g.tick.ng-star-inserted');

      ticks.forEach(textElementtick => {
        var textElement = (textElementtick.querySelector('text') as unknown as SVGTextElement);
        textElement.textContent = (textElementtick.querySelector('title') as unknown as SVGTextElement).textContent;
        const textWidth = textElement.getComputedTextLength();

        const charCountToFit = Math.floor(widthBar / textWidth * textElement.textContent.length);

        if (textWidth > widthBar) {
          textElement.textContent = textElement.textContent.substring(0, charCountToFit - 3) + "...";
        }
      });
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.adjustTextWidth();
  }
}
