import { Injectable, isDevMode, Optional, SkipSelf } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, Observer } from 'rxjs';
import { AppConfigurationService } from '../app.configuration.service';
import { NavigationRequest } from '../core/navigation/models/NavigationRequest.class';
import { NavigationService } from '../core/navigation/navigation.service';
import { DestroyableObjectTrait } from '../shared/utils/destroyableobject.trait';
import { LetsTalkConfigDTO } from '../core/models/ETG_SABENTISpro_Application_Modules_models';
import { filter, take, takeUntil } from 'rxjs/operators';
import {isNullOrUndefined} from "../shared/utils/typescript.utils";

/**
 * Let's Talk configuration service.
 */
@Injectable()
export class LetsTalkService extends DestroyableObjectTrait {
  /**
   * Datos de configuración.
   */
  settings: LetsTalkConfigDTO;

  /**
   * Booleano que indica si el servicio se ha iniciado.
   */
  initialized: boolean = false;

  /**
   * Ultimo request de eventos de navegación.
   */
  request: NavigationRequest = null;

  /**
   * Lets Talk configuration service constructor.
   */
  constructor(
      private appConfigurationService: AppConfigurationService,
      private navigationService: NavigationService,
      private router: Router,
      @Optional() @SkipSelf() parentModule?: LetsTalkService
  ) {
    super()
    // Protección para garantizar que esto está inyecto como SINGLETON
    if (parentModule) {
      throw new Error(
          'LetsTalkService is already loaded. Import it in the AppModule only');
    }
  }

  /**
   * Container selector.
   */
  get container(): Element {
    return document.querySelector('.cx-widget');
  }

  /**
   * Se obtienen los datos de configuracion desde backend para el chat.
   */
  initialize(): void {
    this.appConfigurationService
        .isAppBootstrapped$()
        .pipe(
            filter(value => value),
            take(1))
        .subscribe((v) => {
          this.settings = this.appConfigurationService.getBootstrapData('letstalk-settings');

          if (this.settings && (this.settings.Active || this.settings.Hotjar)) {
            this.track();
          }
        });
  }

  /**
   * Trrack the routes navigation.
   */
  track(): void {
    // No se puede utilizar el navigation service, puesto que una vez que se cierra
    // sesión, el componente HomeComponent no emite cambios de ruta, puesto que
    // no existe.
    // this.navigationService
    //   .currentNavigationRequest
    //   .asObservable()

    this.router.events
        .pipe(
            filter(event => event instanceof NavigationEnd),
            takeUntil(this.componentDestroyed$)
        )
        .subscribe((request: NavigationEnd): void => {
          const url: string = request.url;
          if (url !== this.navigationService.getLoginURL()
              && url !== this.navigationService.getSelectPersonURL()) {
            this.show();
            return;
          }
          this.hide();
        });
  }

  /**
   * Show chat container.
   */
  show(): void {
    if (!this.initialized) {
      this.initializePlugin();
      this.initialized = true;
      return;
    }

    if (this.container) {
      // Ejecutar CSS que muestra el contenedor del chat.
      this.container.setAttribute('style', 'display: block');
    }
  }

  /**
   * Hide the chat container.
   */
  hide(): void {
    // Ejecutar CSS que oculta el contenedor del chat.
    if (this.container) {
      this.container.setAttribute('style', 'display: none');
    }
  }

  /**
   * Inicia el servicio del plugin del chat.
   */
  initializePlugin(): void {
    if (this.settings.Active) {
      this.loadAPI()
          .subscribe(
              () => isDevMode() && console.log('👌 Se arranco el servicio de Chat'),
              (error) => console.error(error));
    }

    if (this.settings.Hotjar && !isNullOrUndefined(this.settings.HotjarScript)) {
      this.loadScript(this.settings.HotjarScript)
          .subscribe(
              () => isDevMode() && console.log('👌 Se arranco el servicio de hotjar'),
              (error) => console.error(error));
    }
  }

  loadScript(scriptContent: string): Observable<void> {
    return new Observable((obs: Observer<void>) => {
      if (!scriptContent) {
        obs.error('🙋🏽‍♀️🙋🏽‍♂️ El contenido del script no está definido.');
      }

      const body: HTMLHeadElement = document.body;
      const script: HTMLScriptElement = document.createElement('script');

      // Asigna el contenido del script directamente
      script.text = scriptContent;

      script.onerror = (e): void => {
        obs.error(e);
        obs.complete();
      };

      script.onload = (): void => {
        obs.next(null);
        obs.complete();
      };

      body.appendChild(script);
    });
  }


  /**
   * Chat script loader.
   */
  loadAPI(): Observable<void> {
    return new Observable((obs: Observer<void>) => {
      if (!this.settings.URL) {
        obs.error('🙋🏽‍♀️🙋🏽‍♂️ La URL del chat no ha sido configurada.');
      }

      const body: HTMLHeadElement = document.body;
      const script: HTMLScriptElement = document.createElement('script');

      script.async = true;
      script.src = this.settings.URL;

      script.onerror = (e): void => {
        obs.error(e);
        obs.complete();
      }

      script.onload = (): void => {
        obs.next(null);
        obs.complete();
      }

      body.appendChild(script);
    });
  }
}
